import Api from '@/services/Index';

// Auth
const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/unihall/applications', config);
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/unihall/applications/' + id);
}

const update = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/unihall/applications/update-payment-status', data);
}

const remove = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/unihall/applications/' + id);
}

const updateRoomAndPrice = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/unihall/applications/update-room-and-price/',data);
}

export default {
    getAll,
    get,
    update,
    remove,
    updateRoomAndPrice
};
